import punycode from "punycode";

export const apiDomain = "api.avalon-avto.ru";
export const timezoneOffsetCurrent = 7 // в Красноярске (4), камчатское время (12), калининградское время (2)

export const domains = [
  {
    domain: "avalon-avto.ru",
    slug: "avalon-avto.ru",
    api: "api.avalon-avto.ru",
    siteId: 459,
    isIndex: false
  },
]
export const devDomain = {
  domain: "avalon-avto.ru",
  slug: "avalon-avto.ru",
  api: "api.avalon-avto.ru",
  siteId: 459,
  isIndex: false
}

export const mainDomain = domains.find(value => value.isIndex) || domains[0]
export function findDomain(host) {
  if (host.split("https://")[1]) {
    let currentHost = host.split("https://")[1];
    return domains.find(value => punycode.toUnicode(value.domain) === punycode.toUnicode(currentHost)) || devDomain;
  } else {
    return domains.find(value => punycode.toUnicode(value.domain) === punycode.toUnicode(host)) || devDomain;
  }
}
